<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="5%"
    width="50%"
    title="批量上传"
    :center="true"
    :before-close="doClose"
  >
    <div  class="addtestpop">
      <div class="ovy-a">
          <div v-if="!load" style="display:flex;flex-direction: column;justify-content: center;padding:30px">
               <p style="font-size:18px;">第1步  下载Excle模板 <el-button class="bgc-bv" @click="downloadExcel('ruleForm')" size="small" style="margin-left:15px">下载模板</el-button></p>
          <p style="display:flex; align-items:center;margin-top:30px;font-size:18px;">第2步  使用模板整理试题,上传整理好的文件
              <el-upload
              style="margin-left:15px"
              v-model="fileName"
              v-if="!fileName"
              class="upload-demo upload-btn"
              :action="actionUrl"
              :on-error="handleError"
              :on-success="handleSuccess"
              :on-change="uploadChange"
              :show-file-list="false"
              :auto-upload="false"
            >
              <el-button size="small" class="bgc-bv">选取文件</el-button>
            </el-upload>
            <span v-else style="padding-left:20px;color:red">
              {{ fileName
              }}
              <a @click="reomveExl" style="color: #5bb5ff;padding-left:5pxd">删除</a>
            </span>
          </p>
          </div>
        <div class="btnBox">
          <el-button class="bgc-bv" @click="formCancel()">取消</el-button>
          <el-button class="bgc-bv" @click="doUpload()" :disabled="!excelUrl">{{btnupText ? '上传中...': '开始上传'}}</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import Form from "@/mixins/Form";
// import tree from "@/components/treePopup";
export default {
  name: "BusinessAdd",
  components: {
    // tree
  },
  mixins: [UploadPic, DatePicker, Form],
  data() {
    return {
      dialogVisible: false,
      fileName:'',
      actionUrl: "",
      excelUrl:'',
      btnupText:false,
    load:false,
     
      //是否上传文件
      updateFile: false,
    };
  },
  computed: {},
  methods: {
 
    // 保存
    formSave(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate(valid => {
        if (valid) {
          this.doAjax(formName);
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项"
          });
        }
      });
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/popquestion.xls";
      link.setAttribute("download", "popquestion.xls");
      document.body.appendChild(link);
      link.click();
    },
    formCancel(formName) {
      this.dialogVisible = false;
      this.fileName = ''
      // this.$router.back();
    },
    /**
     *
     */
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      this.fileName = file.name;
       let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ",  file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.excelUrl = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败"
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    doUpload() {
        this.btnupText = true;
        let parmar = {
            kpointId:this.kpointId,
            excelUrl:this.excelUrl
        }
        this.$post('/biz/pop/question/importPopQuestion',parmar).then(ret => {
            if(ret.status == 0 ) {
                this.$message({
                    message:'导入成功',
                    type:'success'
                })
                this.dialogVisible = false;
                this.btnupText = false;
                this.fileName = "";
                this.excelUrl = "";
                this.$emit('getnewdata')
            } 
        }).catch(err => {
            this.btnupText = false;
            
        })
    },
    reomveExl() {
      this.fileName = "";
      this.excelUrl = "";
    //   this.actionUrl = "";
      this.$forceUpdate();
    },
    showPopUp(kpointId) {
      this.dialogVisible = true;
      this.kpointId = kpointId
      // this.ruleForm.Trainingtype = ruleForm.Trainingtype;
    }
  }
};
</script>
<style lang="less">
.addtestpop {
  .upload-btn {
    width: 5rem;
    height: 32px;
    .el-upload {
      width: 5rem;
      height: 32px !important;
      border: none !important;
    }
  }
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
