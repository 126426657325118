<template>
  <!-- 高级搜索 -->
  <el-dialog
    :visible.sync="centerDialogVisible"
    width="750px"
    top="5%"
    class="dialog"
    center
    @close="closeDialog"
    title="新增试题"
    :close-on-click-modal="false"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="添加试题" name="first"></el-tab-pane>
      <!-- <el-tab-pane label="从题库添加试题" name="second"></el-tab-pane> -->
    </el-tabs>
    <div class="ovy-a">
         <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="题目类型" required>
        <el-radio-group
          v-model="form.questionType"
          @change="formTypeChange"
        >
          <el-radio :label="0">单选题</el-radio>
          <el-radio :label="1">多选题</el-radio>
          <el-radio :label="2">判断题</el-radio>
          <el-radio :label="3">填空题</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="题目内容" prop="questionTitle"  class="areaBox">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入题目内容"
          v-model="form.questionTitle"
        ></el-input>
      </el-form-item>
      <el-form-item label v-if="form.questionType == 0" prop="questionAnswer">
        <div>
          <el-radio-group v-model="form.questionAnswer">
            <div
              v-for="(item, index) in form.optionList"
              :key="index"
              style="display: flex;align-items: center;margin-bottom:18px"
            >
              <el-form-item :prop="'optionList[' + index + '].mins'">
                <el-radio :label="optionFM(index)"
                  >选项{{ optionFM(index) }}</el-radio
                >
                <el-input
                  v-model="form.optionList[index].mins"
                  style="width:220px"
                ></el-input>
                <el-button
                  v-if="index >1"
                  class="de"
                  type="text"
                  @click="delOpenList(index)"
                  >删除选项</el-button
                >
                <el-button
                  v-if="form.questionAnswer == optionFM(index)"
                  type="text"
                  >正确答案</el-button
                >
              </el-form-item>
            </div>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label v-if="form.questionType == 1" prop="questionAnswer2">
        <div>
          <el-checkbox-group v-model="form.questionAnswer2">
            <div
              v-for="(item, index) in form.optionListall"
              :key="index"
              style="display: flex;align-items: center;margin-bottom:18px"
            >
              <el-form-item :prop="'optionListall[' + index + '].mins'">
                <el-checkbox
                  :label="optionFM(index)"
                  @change="debloChangeList(index)"
                  >选项{{ optionFM(index) }}
                  <el-input
                    v-model="form.optionListall[index].mins"
                    style="width:220px"
                  ></el-input>
                </el-checkbox>
                <el-button
                  v-if="index > 2"
                  type="text"
                  @click="delOpenList(index)"
                  >删除</el-button
                >
                <el-button
                  v-if="form.questionAnswer2.indexOf(optionFM(index)) !== -1"
                  type="text"
                  >正确答案</el-button
                >
              </el-form-item>
            </div>
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item
        label="正确答案"
        v-if="form.questionType == 2"
        prop="questionAnswer"
      >
        <div>
          <el-radio-group v-model="form.questionAnswer">
            <el-radio label="T" @change="debloChangeList(index)">正确</el-radio>
            <el-radio label="F" @change="debloChangeList(index)">错误</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item
        label="正确答案"
        v-if="form.questionType == 3"
        prop="questionAnswer"
      >
        <div>
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入正确答案"
            v-model="form.questionAnswer"
            style="width:360px"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        label
        v-if="
          (form.questionType == 0 || form.questionType == 1) &&
            form.optionList.length < 6 && form.optionListall.length < 6
        "
      >
        <el-button @click="addFromOpenList">添加选项</el-button>
      </el-form-item>
      <el-form-item label="题目解析" prop="content">
        <div ref="editors" style="width:100%;position: relative;z-index: 2;" />
      </el-form-item>
    </el-form>
    </div>
   
    <span slot="footer" class="dialog-footer" style="margin-top:30px">
      <el-button class="bgc-bv" @click="docancle()">取 消</el-button>
      <el-button class="bgc-bv" @click="addOptions()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import E from "wangeditor";

let editor = {};
export default {
  name: "ChestLotteryList",
  components: {
    // Empty,
  },
  mixins: [List],
  data() {
    return {
      activeName: "first",
      editBtn: true, //
      centerDialogVisible: false,
      paperList: [],
      form: {
        questionType: 0,
        optionList: [{ mins: "" }, { mins: "" }],
        optionListall: [{ mins: "" }, { mins: "" }, { mins: "" }, { mins: "" }],
        questionAnswer: "",
        questionAnswer2: [],
        content:''
      },
      kpointId: "",
      ruleForm: {},
      rules: {
        questionTitle: [
          { required: true, message: "请输入题目内容", trigger: "blur" }
        ],
        questionAnswer: [
          { required: true, message: "请选择一个正确答案项", trigger:  ['blur', 'change'] }
        ],
        questionAnswer2: [
          {
            required: true,
            message: "请选择至少一个正确答案项",
            trigger: "blur"
          }
        ],
        "optionList[0].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionList[1].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionList[2].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionList[3].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionList[4].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionList[5].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[0].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[1].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[2].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[3].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[4].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[5].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        content: [
          { required: true, message: "请输入答案解析", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    
  },
  mounted() {
    
  },
  beforeDestroy() {
    editor = {};
  },
  computed: {},
  methods: {
    init() {
      // let arr = Object.keys(editor);
      // if (arr.length == 0) {
      //   this.$nextTick(function() {
      //     this.editorInit();
      //   });
      // }
    },
    showPopUp(kpointId) {
      const that = this;
      that.kpointId = kpointId;
      console.log(that.kpointId);
      that.centerDialogVisible = true;
      let arr = Object.keys(editor);
      if (arr.length == 0) {
        that.$nextTick(function() {
          that.editorInit();
          that.form.questionAnalysis=editor.txt.html('')
        });
      }
    },
    // 富文本
    editorInit() {
       editor = new E(this.$refs.editors);
      editor.config.onchange = html => {
        this.form.content = html;
      };
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo" // 重复
      ];
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;
      editor.create();
    },
    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      }
    },
     addOptions() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const that = this;
           if (editor.txt.text().trim() == "") {
          that.$message.warning("请输入答案解析");
          return;
        }
          let parmar = {
            kpointId: that.kpointId,
            questionScore: that.form.questionScore,
            questionTitle: that.form.questionTitle,
            questionType: that.form.questionType,
            questionId: that.form.questionId,
            questionAnswer:
              that.form.questionType == 1
                ? that.form.questionAnswer2.join("")
                : that.form.questionAnswer,
            questionAnalysis:  editor.txt.html().replace(/<p><br><\/p>/g,'')
          };
         if (that.form.questionType == 0) {
            if (that.form.optionList[0]) {
              parmar.questionItem1 = that.form.optionList[0].mins;
            }
            if (that.form.optionList[1]) {
              parmar.questionItem2 = that.form.optionList[1].mins;
            }
            if (that.form.optionList[2]) {
              parmar.questionItem3 = that.form.optionList[2].mins;
            }
            if (that.form.optionList[3]) {
              parmar.questionItem4 = that.form.optionList[3].mins;
            }
            if (that.form.optionList[4]) {
              parmar.questionItem5 = that.form.optionList[4].mins;
            }
            if (that.form.optionList[5]) {
              parmar.questionItem6 = that.form.optionList[5].mins;
            }
          } else if(that.form.questionType == 1){
             if (that.form.optionListall[0]) {
              parmar.questionItem1 = that.form.optionListall[0].mins;
            }
            if (that.form.optionListall[1]) {
              parmar.questionItem2 = that.form.optionListall[1].mins;
            }
            if (that.form.optionListall[2]) {
              parmar.questionItem3 = that.form.optionListall[2].mins;
            }
            if (that.form.optionListall[3]) {
              parmar.questionItem4 = that.form.optionListall[3].mins;
            }
            if (that.form.optionListall[4]) {
              parmar.questionItem5 = that.form.optionListall[4].mins;
            }
            if (that.form.optionListall[5]) {
              parmar.questionItem6 = that.form.optionListall[5].mins;
            }
          }
          that
            .$post("/biz/pop/question/insertPopQuestion", parmar)
            .then(ret => {
              if (ret.status == 0) {
                that.centerDialogVisible = false;
                that.$emit("getnewdata");
                that.$emit("subShows", true);
                editor.txt.clear();
              }
            })
            .catch(err => {
              console.log(err);
            });
          console.log(that.form);
        }  else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    delOpenList(index) {
       if(this.form.questionType == 0) {
           this.form.optionList.splice(index, 1);
      } else if(this.form.questionType == 1) {
             this.form.optionListall.splice(index, 1);

      }
   
    },
    addFromOpenList() {
      let data = {
        mins: ""
      };
      if(this.form.questionType == 0) {
        this.form.optionList.push(data);
      } else if(this.form.questionType == 1) {
         this.form.optionListall.push(data);
      }
      
     
      this.$forceUpdate();
    },
    docancle() {
      this.centerDialogVisible = false;
      this.empty();
    },
    empty() {
      this.form = {
        questionType: 0,
        optionList: [{ mins: "" }, { mins: "" }],
        optionListall: [{ mins: "" }, { mins: "" }, { mins: "" }, { mins: "" }],
        questionAnswer: "",
        questionAnswer2: [],
        content:'',
      };
      this.$refs['form'].resetFields();
      editor.txt.clear();
    },
    closeDialog() {
      this.centerDialogVisible = false;
      this.empty();
    },
    formTypeChange(value) {
      console.log(value);
      
      this.$refs['form'].resetFields();
      editor.txt.clear();
      this.form = {
        questionType: value,
        optionList:[{ mins: "" }, { mins: "" }],
        optionListall: [{ mins: "" }, { mins: "" }, { mins: "" }, { mins: "" }],
        questionAnswer2: [],
        questionAnswer:'',
      }
    }
  }
};
</script>
<style lang="less">
.dialog {
    .el-dialog__body {
    height:600px;
    }   
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: 9.375rem;
        }
      }
    }
  }
}
.searchbox {
  padding: 0.9rem 0 0 1.775rem;
  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;
    &::before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -17px;
    }
  }
}
.tixing {
  font-size: 14px;
  height: fit-content;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.areaBox  {
  .el-textarea {
    .el-textarea__inner {
      min-height:150px !important;
       resize: none;
    }
  }
}
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    display: flex;
    border-left: 3px solid rgb(92, 107, 232);
    span {
      margin-left: 20px;
    }
    margin-bottom: 30px;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div {
    margin-bottom: 5px;
  }
  > div:last-child {
    border: 0;
    margin-bottom: 0;
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    width: 70%;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 70%;
  button {
    padding: 12px 30px;
  }
}
</style>
