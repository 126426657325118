<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的课件</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">弹题配置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <p style="padding: 0 2rem;font-size: 1rem; font-weight:800">弹题配置</p>
          <div class="searchbox" style="margin-bottom:5px">
            <div class="btnBox">
              <el-button
                v-show="stu != 'platform'"
                style="padding:8px 20px;aling-self:flex-start"
                class="bgc-bv"
                @click="addquestion(kpointId)"
                >新增试题</el-button
              >
              <el-button
                v-show="stu != 'platform'"
                style="padding:8px 20px;aling-self:flex-start"
                class="bgc-bv"
                @click="addquestionUpload(kpointId)"
                >批量上传</el-button
              >
            </div>
          </div>
        </div>
        <div class="ovy-a">
          <div class="framePage-scroll flexdc">
            <div class="ovy-a flex1" id="list-box">
              <div class="list" v-for="(item, index) in tableData" :key="index">
                <div class="list-item df">
                  <!-- <span
                  :class="tixing {item.questionType == 0? 'tixing1':item.questionType == 1 ? 'tixing2':item.questionType == 2 ? 'tixing3':item.questionType == 3 ? 'tixing4':''}"
                  >{{ $setDictionary("QUESTIONTYPE", item.questionType) }}</span
                  >-->
                  <span
                    class="tixing"
                    :class="{
                      tixing1: item.questionType == 0,
                      tixing2: item.questionType == 1,
                      tixing3: item.questionType == 2,
                      tixing4: item.questionType == 3,
                    }"
                    >{{
                      $setDictionary("QUESTIONTYPE", item.questionType)
                    }}</span
                  >
                  <div class="subject flex1">
                    <div class="subject-title">
                      <span>{{ item.questionTitle }}</span>
                    </div>
                    <div class="option" v-if="item.questionType == 0">
                      <span>A.{{ item.questionItem1 }}</span>
                      <span>B.{{ item.questionItem2 }}</span>
                      <span v-if="item.questionItem3"
                        >C.{{ item.questionItem3 }}</span
                      >
                      <span v-if="item.questionItem4"
                        >D.{{ item.questionItem4 }}</span
                      >
                      <span v-if="item.questionItem5"
                        >E.{{ item.questionItem5 }}</span
                      >
                      <span v-if="item.questionItem6"
                        >F.{{ item.questionItem6 }}</span
                      >
                    </div>
                    <div class="option" v-if="item.questionType == 1">
                      <span v-if="item.questionItem1">A.{{ item.questionItem1 }}</span>
                      <span v-if="item.questionItem2">B.{{ item.questionItem2 }}</span>
                      <span v-if="item.questionItem3">C.{{ item.questionItem3 }}</span>
                      <span v-if="item.questionItem4">D.{{ item.questionItem4 }}</span>
                      <span v-if="item.questionItem5"
                        >E.{{ item.questionItem5 }}</span
                      >
                      <span v-if="item.questionItem6"
                        >F.{{ item.questionItem6 }}</span
                      >
                    </div>
                    <div class="analysis">
                      正确答案：{{ item.questionAnswer }}
                    </div>
                    <div style="display:flex">
                      <p>答案解析：</p>
                      <span class="analysis" v-html="item.questionAnalysis">{{
                        item.questionAnalysis
                      }}</span>
                    </div>
                    <div
                      class="df"
                      style=" flex-wrap: nowrap;justify-content: flex-end;align-items: flex-end;flex: 1;"
                      v-show="stu != 'platform'"
                    >
                      <div style="white-space: nowrap;">
                        <el-button
                          style="padding:8px 20px"
                          @click="onEdit(item.popQuestionId,item.kpointId)"
                          >编辑</el-button
                        >
                        <el-button
                          style="padding:8px 20px"
                          @click="onDel(item.popQuestionId)"
                          >删除</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <TestpaperZz ref="TestpaperZz" @getnewdata="getData" @subShows="subShows" />
    <TestPaperZzedit ref="TestPaperZzedit" @getnewdata="getData" @subShows="subShows" />
    <addquestionUpload ref="addquestionUpload" @getnewdata="getData" @subShows="subShows" />
  </div>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import TestpaperZz from "@/views/resourse/popup/TestPaperZz.vue";
import TestPaperZzedit from "@/views/resourse/popup/TestPaperZzedit.vue";
import addquestionUpload from "@/views/resourse/popup/addquestionUpload.vue";
import List from "@/mixins/List";
export default {
  name: "elasticList",
  components: {
    PageNum,
    TestpaperZz,
    TestPaperZzedit,
    addquestionUpload
  },
  mixins: [List],
  data() {
    return {
      userName: "",
      activeName: "first",
      stu: "add",
      editBtn: true, //
      searchName: "",
      searchIdcard: "",
      searchPhone: "",
      centerDialogVisible: false,
      paperList: [],
      form: {
        type: "1",
        radio: null,
        checkList: [],
        optionList: [
          { mins: "", state: false },
          { mins: "", state: false },
          { mins: "", state: false },
          { mins: "", state: false },
        ],
        testnum: null,
        selectState: true,
      },
      kpointId: "",
      ruleForm: {
        paperTotalScore: "",
        paperName: "",
        paperScore: "",
        salesState: "",
        Trainingtype: "",
      },
      datatreeList: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      Topictype: [
        {
          value: "0",
          label: "单选题",
        },
        {
          value: "1",
          label: "多选题",
        },
        {
          value: "2",
          label: "判断题",
        },
        {
          value: "3",
          label: "填空题",
        },
      ],
      TopictypeVal: "",
      topiccontent: "",
      subjectlist: [], // 题目数据
      rules: {
        paperTotalScore: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        paperName: [{ required: true, message: "请输入", trigger: "blur" }],
        paperScore: [{ required: true, message: "请输入", trigger: "blur" }],
        salesState: [{ required: true, message: "请输入", trigger: "change" }],
        trainTypeId: [{ required: true, message: "请输入", trigger: "change" }],
        Trainingtype: [
          { required: true, message: "请输入", trigger: "change" },
        ],
      },
      notState: "",
      addState: "",
      subShow: false,
      params: {}, // 培训类型参数
    };
  },
  created() {},
  mounted() {
    // const height = document.getElementById('list-box').offsetHeight;
  },
  computed: {},
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    subShows(params) {
      this.subShow = params;
    },
    init() {
        const query = this.$route.query;
        this.kpointId = query.kpointId;
      //   this.paperOrgin = query.paperOrgin;
      //   this.stu = query.stu;
      //   this.addState = query.addState || "";
      //   this.notState = query.notState || "";
      //   if (this.parperId) {
      //     this.getEdit(this.parperId);
      //     this.getData(-1);
      //   }
      //   this.getList();
      this.getData(-1)
    },
    // 培训类型
    getList() {
      this.$post("/sys/category/train-type/tree").then((ret) => {
        if (ret.status == 0) {
          this.datatreeList = ret.data;
        }
      });
    },
    optionFM(msg) {
      if (msg == 0) {
        return "选项A";
      } else if (msg == 1) {
        return "选项B";
      } else if (msg == 2) {
        return "选项C";
      } else if (msg == 3) {
        return "选项D";
      } else if (msg == 4) {
        return "选项E";
      } else if (msg == 5) {
        return "选项F";
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        kpointId:  this.kpointId,
      };
      // if (this.TopictypeVal) {
      //   params.questionType = this.TopictypeVal;
      // }
      // if (this.topiccontent) {
      //   params.questionTitle = this.topiccontent;
      // }
      this.doFetch({
        url: "/biz/pop/question/queryPopQuestionList",
        params,
        pageNum,
      });
      // this.getEdit(this.parperId);
    },
    //
    doEdit() {
      this.editBtn = false;
    },
    // 试题删除
    onDel(popQuestionId) {
      const that = this;
      that
        .$confirm("你确定要删除该试题吗", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that
            .$post("/biz/pop/question/deletePopQuestion", { popQuestionId })
            .then((ret) => {
              if (ret.status == 0) {
                that.subShow = true;
                that.getData();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          return;
        });
    },
    onEdit(popQuestionId,kpointId) {
      this.$refs.TestPaperZzedit.showPopUp(popQuestionId,kpointId);
    },
    addquestion(kpointId) {
      this.$refs.TestpaperZz.showPopUp(kpointId);
    },
    addquestionUpload(kpointId) {
      this.$refs.addquestionUpload.showPopUp(kpointId);

    }
  },
};
</script>
<style lang="less">
.el-input__inner {
  line-height: 32px;
  height: 32px;
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
        }
      }
    }
  }
}
.searchbox {
  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;
    &::before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -17px;
    }
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.searchbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.changetreestyle {
  .treeinput {
    .el-input {
      flex: 0;
    }
  }
}
.treeinput {
  .el-form-item__content {
    width: 36rem;
  }
}
</style>
